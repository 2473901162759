export function Home() {
  return (
    <div style={{ display: "flex" }} className="home">
      <div className="logo-image-home"></div>
      <h2>
        What am i going
        <br />
        to do next...
      </h2>
    </div>
  );
}
